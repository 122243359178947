import empty from "../assets/images/empty.svg";

export const NoRecords = ({
  message,
  description,
  showImage = true,
  showDescription = true,
}) => {
  return (
    <div className="empty-container">
      {showImage && <img className="empty-img" src={empty} />}
      <h3>{message || "Oops! No Data to Display"}</h3>
      {showDescription && (
        <small>{description || "Select a Project to view data"}</small>
      )}
    </div>
  );
};
