import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import UserService from "../pages/users/services/User.service";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FormInput } from "./FormInput";
import { validate } from "validate.js";
import { CustomSelect } from "./CustomSelect";

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
  },
  username: {
    presence: { allowEmpty: false, message: "is required" },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
  },
  roleId: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const styles = makeStyles((theme) => ({
  dialogTitle: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: "20px",
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  dialogActions: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  formControl: {
    marginBottom: theme.spacing(4),
  },
  margin: {
    marginRight: "12px",
  },
  RadioGroup: {
    flexDirection: "row",
  },
}));
const initValues = {
  firstName: "",
  lastName: "",
  username: "",
  password: "",
  roleId: null,
};
export default function AddUserModal({ loadUsers }) {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({ ...initValues });
  const [showPassword, setShowPassword] = React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    loadRoles();
    setValues(initValues);
  }, []);

  const loadRoles = async () => {
    const roles = await UserService.getRoles();
    setRoles(roles);
  };

  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });

  const clearForm = () => {
    setValues(initValues);
    setErrors({});
    setTouched({});
  };

  const validateForm = (values) => {
    const errors = validate(values, schema);
    setErrors({ ...errors });
    return !errors ? true : false;
  };

  const handleChange = (name, value) => {
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
    validateForm(newValues);
    setTouched({ ...touched, [name]: true });
  };

  const hasError = (field) => (touched[field] && errors[field] ? true : false);

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const saveData = async () => {
    try {
      setIsLoading(true);

      let response = await UserService.createUser(values);
      if (response) {
        setAlertData({
          title: "Success",
          type: "success",
          message: `Successfully created the user`,
        });
        loadUsers();
        setIsLoading(false);
        setSnackBarOpen(true);
        handleClose();
        return response.data;
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.status == 409) {
        setErrors({ ...errors, username: ["username already used"] });
      } else {
        setAlertData({
          title: "Error",
          type: "error",
          message: error.message || "Something went wrong",
        });
        setSnackBarOpen(true);
      }
      throw error;
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    try {
      setTouched({
        username: true,
        firstName: true,
        lastName: true,
        password: true,
        roleId: true,
      });
      if (validateForm(values)) {
        saveData();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Tooltip title="Add New User">
        <Fab
          color="primary"
          aria-label="add record"
          className={classes.margin}
          size="medium"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal"
      >
        <DialogTitle id="modal-title" className={classes.dialogTitle}>
          Add New User
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {isLoading && (
          <LinearProgress
            className={classes.loadingBar + " loading-bar"}
            color="primary"
          />
        )}

        <DialogContent dividers className={classes.dialogContent}>
          <form className={classes.root}>
            <FormGroup>
              <CustomSelect
                label="Role"
                error={hasError("roleId") && errors.roleId[0]}
                hasError={hasError("roleId")}
                options={roles}
                valueKey="id"
                labelKey="name"
                propertyName="roleId"
                value={values.roleId}
                onChange={(value) => handleChange("roleId", value)}
              />
              <FormInput
                error={hasError("firstName") && errors.firstName[0]}
                hasError={hasError("firstName")}
                value={values.firstName}
                onChange={(value) => handleChange("firstName", value)}
                label="First Name"
              />
              <FormInput
                error={hasError("lastName") && errors.lastName[0]}
                hasError={hasError("lastName")}
                value={values.lastName}
                onChange={(value) => handleChange("lastName", value)}
                label="Last Name"
              />
              <FormInput
                error={hasError("username") && errors.username[0]}
                hasError={hasError("username")}
                value={values.username}
                onChange={(value) => handleChange("username", value)}
                label="Username"
              />

              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  error={hasError("password") && errors.password[0]}
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
                {hasError("password") && errors.password[0] && (
                  <FormHelperText error id="accountId-error">
                    Password cannot be empty
                  </FormHelperText>
                )}
              </FormControl>
            </FormGroup>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save User
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={alertData.type}
          variant="filled"
        >
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
