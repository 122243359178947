import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Layout from "./layout/Layout";
import UserLayout from "./pages/users/UserLayout.js";
import Login from "./pages/login/Login";
import AddProjects from "./pages/project/AddProjects";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { JWTProvider as AuthProvider } from "./context/JWTContext";
import useAuth from "./hooks/useAuth";
import ClientHome from "./pages/client/ClientHome";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#5850EB",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#b52832",
      contrastText: "#fff",
    },
  },
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard" component={Layout} />
            <PrivateRoute path="/user" component={UserLayout} />
            <PrivateRoute path="/client" component={ClientHome} />
            <PrivateRoute path="/projects" component={AddProjects} />
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
