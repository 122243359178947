import { TextField, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(4),
  },
}));

export const FormInput = ({
  propertyName,
  label,
  value,
  error,
  hasError,
  onChange,
  onBlur,
  disabled = false,
  required = true,
  fullWidth = false,
  ...rest
}) => {
  const classes = styles();

  return (
    <TextField
      error={hasError}
      helperText={hasError && error}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      onBlur={onBlur}
      required={required}
      name={propertyName}
      label={label}
      className={classes.formControl}
      variant="outlined"
      disabled={disabled}
      fullWidth={fullWidth}
      {...rest}
    />
  );
};
