import { Button, Menu, MenuItem, withStyles } from "@material-ui/core";
import React from "react";
import EventNoteIcon from "@material-ui/icons/EventNote";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    fontWeight: "normal !important",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const MenuSelector = ({ items, onSelect, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key) => {
    setAnchorEl(null);
    onSelect(key);
  };

  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        disableElevation
        disabled={!items}
        onClick={handleClick}
        {...props}
      >
        <EventNoteIcon />
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {items &&
          items.map((item, index) => (
            <MenuItem
              key={index}
              style={{ fontWeight: "normal !important" }}
              onClick={() => handleClose(item.key)}
            >
              {item.label}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};
