import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultiSelect = ({ options, onChange, selectedItems, label }) => {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      size="small"
      value={options.filter((x) => selectedItems.includes(x.key))}
      onChange={(event, values) => onChange(values.map((x) => x.key))}
      getOptionLabel={(option) => option.value}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              color="primary"
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selectedItems.includes(option.key)}
            />
            {option.value}
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Levels"
        />
      )}
    />
  );
};
