import { Button } from "@material-ui/core";
import React from "react";
import ProjectsIco from "../assets/images/projects.svg";

export const FilterButton = ({ onClick, disabled, icon, label, count }) => {
  return (
    <div className="input-wrapper">
      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        disableElevation
        disabled={disabled}
        size="medium"
        startIcon={<img src={icon || ProjectsIco} />}
      >
        {label}
        {!!count && <span className="bubble">{count}</span>}
      </Button>
    </div>
  );
};
