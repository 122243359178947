import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { BASE_API, assetEndpoints } from "../config";
import { AddCircleOutlined, CancelOutlined, Delete } from "@material-ui/icons";
import assetService from "../pages/project/services/assetService";
import DeleteConfirm from "../pages/project/DeleteConfirm";
import { Alert, AlertTitle } from "@material-ui/lab";
import { dataURLtoFile } from "../utils/formUtils";
import { CustomizedProgressBar } from "./CircularProgress";

const useStyles = makeStyles((theme) => ({
  thumbnailImage: {
    width: "100%",
    minWidth: "140px",
    height: "100px",
    objectFit: "cover",
    objectPosition: "center",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "5px",
  },
}));

function ImageViewer({ assetId, imageSchema }) {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [loadingKey, setLoadingKey] = useState();

  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  useEffect(() => {
    loadImages();
  }, [assetId]);

  const loadImages = async () => {
    const data = await assetService.getAssetData(assetId);
    setImages(data.assetImages);
  };

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });
  const [selectedRecord, setSelectedRecord] = useState();
  // const [uploadedImage, setUploadedImage] = useState(null);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const getImageUrl = (image) =>
    `${BASE_API}${assetEndpoints.assetImage}${image.imageKey}`;

  const deleteImage = async () => {
    try {
      let response = await assetService.deleteImages([selectedRecord]);
      if (response) {
        setOpenSnackBar(true);
        loadImages();
        setAlertData({
          title: "Success",
          type: "success",
          message: `Successfully deleted the client`,
        });
        return response.data;
      }
    } catch (error) {
      setOpenSnackBar(true);
      setAlertData({
        title: "Error",
        type: "error",
        message: error.message || "Something went wrong",
      });
    } finally {
      setSelectedRecord();
    }
  };

  const handleImageUpload = async (imageKey, imageDataURL) => {
    setLoadingKey(imageKey);
    const formData = new FormData();
    formData.append(imageKey, dataURLtoFile(imageDataURL, "image.png"));

    try {
      await assetService.uploadAssetImage(assetId, formData);
      setOpenSnackBar(true);
      setLoadingKey();
      loadImages();
      setAlertData({
        title: "Success",
        type: "success",
        message: `Image uploaded successfully.`,
      });
    } catch (error) {
      setLoadingKey();
      setOpenSnackBar(true);
      setAlertData({
        title: "Error",
        type: "error",
        message: error.message || "Something went wrong",
      });
    }
  };

  return (
    <div>
      <Box
        alignItems="center"
        padding={1}
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridTemplateRows="1fr 1fr"
      >
        {imageSchema.map((schema, index) => {
          const assetImages = images.filter((x) => x.imageType == schema.key);

          return assetImages.length ? (
            assetImages.map((image) => (
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "space-between",
                    padding: "0 5px",
                  }}
                >
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    {schema.label}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => setSelectedRecord(image.imageKey)}
                  >
                    <CancelOutlined color="error" />
                  </IconButton>
                </div>
                <Card
                  elevation={false}
                  onClick={() =>
                    openLightbox(images.findIndex((x) => x.id == image.id))
                  }
                  style={{
                    margin: 5,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={getImageUrl(image)}
                    alt="..."
                    loading="lazy"
                    className={classes.thumbnailImage}
                  />
                </Card>
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="caption" style={{ fontWeight: "bold" }}>
                  {schema.label}
                </Typography>
              </div>
              <Card
                elevation={false}
                style={{
                  margin: 5,
                  cursor: "pointer",
                }}
              >
                <ImageUpload
                  isLoading={loadingKey === schema.key}
                  onImageUpload={(imageUrl) =>
                    handleImageUpload(schema.key, imageUrl)
                  }
                  classes={classes}
                />
              </Card>
            </div>
          );
        })}
      </Box>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map((image) => ({
                src: getImageUrl(image),
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>

      <DeleteConfirm
        open={selectedRecord}
        onClose={() => setSelectedRecord()}
        onConfirm={deleteImage}
        requirePasswordConfirmation={false}
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          onClose={() => setOpenSnackBar(false)}
          severity={alertData.type}
          variant="filled"
        >
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ImageViewer;

function ImageUpload({ onImageUpload, classes, isLoading }) {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataURL = e.target.result;
        onImageUpload(imageDataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        hidden
        onChange={handleFileChange}
      />

      <IconButton
        onClick={handleButtonClick}
        className={classes.thumbnailImage}
        aria-label="close"
        size="small"
      >
        {isLoading ? <CircularProgress /> : <AddCircleOutlined />}
      </IconButton>
    </div>
  );
}
