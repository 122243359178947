import React, { useImperativeHandle, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";

import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import UserService from "../pages/users/services/User.service";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const styles = makeStyles((theme) => ({
  dialogTitle: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: "20px",
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  dialogActions: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  formControl: {
    marginBottom: theme.spacing(4),
  },
  margin: {
    marginRight: "12px",
    marginBottom: "24px",
  },
  RadioGroup: {
    flexDirection: "row",
  },
}));

const UserPasswordModal = (props, ref) => {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const [error, setError] = React.useState();

  const [formData, setFormData] = React.useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [alertData, setAlertData] = React.useState({
    title: "success",
    type: "success",
    message: "success",
  });
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  useImperativeHandle(ref, () => ({
    handleClickOpen() {
      handleClickOpen();
    },
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSnackBarOpen(false);
    setOpen(false);
    setFormData({
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
    });
    setError();
  };

  const handleSaveUser = async () => {
    try {
      let response = await UserService.resetUserPassword(props.userId, {
        newPassword: formData.password,
      });
      if (response) {
        setAlertData({
          title: "Success",
          type: "success",
          message: "Successfully reset the password",
        });

        props.loadUsers();
        setSnackBarOpen(true);
        setOpen(false);
        return response.data;
      }
    } catch (error) {
      setError(
        error.data && error.data.message
          ? error.data.message
          : "Something went wrong"
      );
      throw error;
    }
  };

  const handleChange = (value) => {
    setFormData({ ...formData, ...value });
    setError();
  };

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal"
      >
        <DialogTitle id="modal-title" className={classes.dialogTitle}>
          Reset passoword
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <form className={classes.root}>
            <PasswordField
              showPassword={formData.showPassword}
              password={formData.password}
              handleChange={(value) => handleChange({ password: value })}
              setShowPassword={(value) => handleChange({ showPassword: value })}
              label="Password"
            />
            <PasswordField
              showPassword={formData.showConfirmPassword}
              password={formData.confirmPassword}
              handleChange={(value) => handleChange({ confirmPassword: value })}
              setShowPassword={(value) =>
                handleChange({ showConfirmPassword: value })
              }
              label="Confirm Password"
            />
          </form>
        </DialogContent>
        {error && (
          <Alert style={{ width: "auto" }} severity="error">
            {error}
          </Alert>
        )}
        {!!formData.confirmPassword.length &&
          formData.password !== formData.confirmPassword && (
            <Alert style={{ width: "auto" }} severity="error">
              New password and confirm password does not match
            </Alert>
          )}
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !formData.password.length ||
              formData.password !== formData.confirmPassword
            }
            onClick={handleSaveUser}
          >
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.type} variant="filled">
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export const PasswordField = ({
  showPassword,
  password,
  handleChange,
  setShowPassword,
  label,
}) => {
  const classes = styles();
  return (
    <FormGroup>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => handleChange(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
      </FormControl>
    </FormGroup>
  );
};

export default forwardRef(UserPasswordModal);
